export const DATA = [
	{
		id: "1",
		label: "Ana Sayfa",
		link: "/",
	},
	{
		id: "2",
		label: "QR Kod Okuyucu",
		link: "/qrreader",
		source: "",
	},
	{
		id: "3",
		label: "QR Kod Tarayıcı",
		link: "/qrscan",
		source: "",
	},
	{
		id: "4",
		label: "QR Fiyat Etiketi",
		link: "/pricetag",
		source: "",
	},
	{
		id: "5",
		label: "eWallet",
		link: "/ewallet",
		source: "",
	},
];
